import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  background: #f7f7f7;

  iframe {
    border: 0;
    display: block;
  }
`

const Map = () => (
  <Wrapper>
    <iframe
      src="https://snazzymaps.com/embed/167379"
      width="100%"
      height="478px"
      title="Karte mit markiertem Standort von Oberhuber Jenal."
    ></iframe>
  </Wrapper>
)

export default Map
