import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Content from './Content'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.footer`
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  padding-top: 120px;
  padding-bottom: 80px;
  text-align: center;
  font-size: 13px;

  @media (max-width: 375px) {
    padding-top: 60px;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  padding: 8px 3px;
  display: inline-block;

  &::after {
    content: attr(title);
    display: block;
    font-weight: 500;
    visibility: hidden;
    overflow: hidden;
    line-height: 0;
    height: 0;
    padding: 0 3px;
  }

  &:hover,
  &.is-active {
    font-weight: 500;
  }
`

const FooterSmall = () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <Content>
        <StyledLink
          to={intl.locale === 'de' ? '/de/datenschutz' : '/en/privacy-policy'}
          title={intl.formatMessage({ id: 'nav.privacy ' })}
        >
          {intl.formatMessage({ id: 'nav.privacy' })}
        </StyledLink>
        <StyledLink
          to={intl.locale === 'de' ? '/de/impressum' : '/en/imprint'}
          title={intl.formatMessage({ id: 'nav.imprint' })}
        >
          {intl.formatMessage({ id: 'nav.imprint' })}
        </StyledLink>
      </Content>
    </Wrapper>
  )
}

export default FooterSmall
