import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Map from '../components/Map'
import FooterSmall from '../components/FooterSmall'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Cols = styled.div`
  margin-left: -60px;
  margin-right: -60px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-left: -30px;
    margin-right: -30px;
  }

  @media (max-width: 480px) {
    display: block;
    text-align: center;
  }
`

const Col = styled.div`
  padding: 0 60px;

  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const ContactPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout footer={{ hide: true }}>
      <SEO title={intl.formatMessage({ id: 'nav.contact' })} />
      <Intro image={data.introImage} />
      <Content>
        <Title align="center">
          {intl.formatMessage({ id: 'contact.heading' })}
        </Title>
        <Cols>
          <Col>
            <Fade bottom>
              <p>
                Oberhuber Jenal
                <br />
                Rechtsanwälte AG
                <br />
                Wuhrstrasse 14
                <br />
                LI-9490 Vaduz
              </p>
            </Fade>
          </Col>
          <Col>
            <Fade bottom>
              <p>
                {intl.formatMessage({ id: 'contact.phone' })} +423 237 70 80
                <br />
                <a href="mailto:info@oberhuberjenal.li">
                  info@oberhuberjenal.li
                </a>
                <br />
                <a href="https://www.oberhuberjenal.li">
                  www.oberhuberjenal.li
                </a>
              </p>
            </Fade>
          </Col>
        </Cols>
      </Content>
      <FooterSmall />
      <Map />
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    introImage: file(relativePath: { eq: "kontakt_head.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
